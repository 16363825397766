<template>
    <div class="write_detail">
        <div class="write_detail_top">
            <topHeader></topHeader>
        </div>
        <div class="content">
            <div class="content_white">
                <div class="step_label">
                    <el-steps
                            :space="200"
                            :active="active"
                            finish-status="success"
                            align-center
                    >
                        <el-step title="填写邮箱"></el-step>
                        <el-step title="作家实名"></el-step>
                        <el-step title="提交申请"></el-step>
                    </el-steps>
                </div>
                <div class="out_box">
                    <div class="step_one" v-if="step_num === 1">
                        <div class="step_box">
                            <textInput
                                    :option="eMail"
                                    :placeholder="'请输入常用邮箱'"
                                    @enter_next="enter_next"
                            ></textInput>
                        </div>
                        <div class="step_next">
                            <el-button type="primary" @click="next_eMail(1)"
                            >下一步
                            </el-button
                            >
                        </div>
                    </div>
                    <div class="step_two" v-if="step_num === 2">
                        <div class="step_box">
                            <el-form
                                    ref="ruleform"
                                    :model="form"
                                    :rules="rules"
                                    label-width="80px"
                            >
                                <el-form-item label="真实姓名" prop="name">
                                    <el-input v-model="form.name" style="width: 200px"></el-input>
                                </el-form-item>
                                <el-form-item label="笔名" prop="author_name">
                                    <el-input
                                            v-model="form.author_name"
                                            style="width: 200px"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="常住地址" prop="address">
                                    <el-input
                                            v-model="form.address"
                                            style="width: 200px"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="身份证号" prop="id_card">
                                    <!-- type="number" -->
                                    <el-input
                                            v-model="form.id_card"
                                            style="width: 200px"
                                            type="text"
                                            class="number_input"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="银行卡号" prop="card_no">
                                    <el-input
                                            v-model="form.card_no"
                                            style="width: 200px"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="开户行" prop="card_bank">
                                    <el-input
                                            v-model="form.card_bank"
                                            style="width: 200px"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="身份证照片" prop="card_front">
                                    <div class="card_box">
                                        <div class="card_item">
                                            <div class="card_front card">
                                                <upPic
                                                        v-model="form.card_front"
                                                        :up_type="'system'"
                                                ></upPic>
                                            </div>
                                            <span>正面</span>
                                        </div>
                                        <div class="card_item">
                                            <div class="card_back card">
                                                <upPic
                                                        v-model="form.card_back"
                                                        :up_type="'system'"
                                                ></upPic>
                                            </div>
                                            <span>反面</span>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="step_next">
                            <el-button type="primary" @click="submit(3, 'ruleform')"
                            >提交申请
                            </el-button
                            >
                        </div>
                    </div>
                    <div class="step_three" v-if="step_num === 3">
                        <div class="success_word">
                            <div>提交申请成功</div>
                            <span>作家审核结果会在1~3个工作日内通知您，</span>
                            <span>请耐心等待哦~</span>
                            <span>{{ time }}秒 跳转至首页</span>
                        </div>
                        <div class="step_next">
                            <el-button
                                    type="primary"
                                    @click="$router.push({ path: '/index' })"
                            >立即跳转
                            </el-button
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import topHeader from "@/components/main/top-header";
    import textInput from "@/components/input/title_input";
    import upPic from "@/components/input/up_pic";
    import {author_apply} from "@/https/author_center.js";

    export default {
        name: "write_detail",
        components: {
            topHeader,
            textInput,
            upPic,
        },

        data() {
            return {
                active: 0,
                radio: 1,
                step_num: 1,
                options: [{label: "玄幻", value: 1}],
                form: {
                    name: "",
                    address: "",
                    id_card: "",
                    card_no: "",
                    card_bank: "",
                    card_front: "",
                    card_back: "",
                    author_name: "",
                },
                rules: {
                    name: [
                        {required: true, message: "真实姓名不能为空", trigger: "blur"},
                    ],
                    address: [
                        {required: true, message: "常住地址不能为空", trigger: "blur"},
                    ],
                    id_card: [
                        {required: true, message: "身份证号不能为空", trigger: "blur"},
                        {required: true, validator: this.card_id_rule, trigger: "blur"},
                    ],
                    card_no: [
                        {required: true, message: "银行卡号不能为空", trigger: "blur"},
                        {required: true, validator: this.bank_no_rule, trigger: "blur"},
                    ],
                    card_bank: [
                        {required: true, message: "开户行不能为空", trigger: "blur"},
                    ],
                    card_front: [
                        {required: true, message: "身份证照片不能为空", trigger: "change"},
                    ],
                    author_name: [
                        {required: true, message: "笔名不能为空", trigger: "blur"},
                    ],
                },
                eMail: {
                    text: "",
                    label: "",
                },
                time: 5,
                set_time: null,
                person_info: JSON.parse(sessionStorage.getItem('person_info'))
            };
        },
        watch: {
            step_num(n) {
                if (n === 3) {
                    this.time_init();
                }
            },
        },
        methods: {
            enter_next(e) {
                if (e.keyCode === 13) {
                    this.next_eMail(1);
                }
            },
            card_id_rule(rule, value, callback) {
                var reg = new RegExp(
                    /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/
                );
                if (!reg.test(value)) {
                    callback(new Error("身份证号码格式不正确"));
                }
                callback();
            },
            bank_no_rule(rule, value, callback) {
                var reg = new RegExp(/^[1-9]\d{9,29}$/);
                if (!reg.test(value)) {
                    callback(new Error("银行卡号格式不正确"));
                }
                callback();
            },
            next_eMail(num) {
                var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
                if (reg.test(this.eMail.text)) {
                    this.next(num);
                } else {
                    this.$message.warning("请正确填写邮箱格式");
                }
            },
            submit(num, formName) {
                let params = {
                    email: this.eMail.text,
                    real_name: this.form.name,
                    address: this.form.address,
                    id_code: this.form.id_card,
                    bank_code: this.form.card_no,
                    bank_opening: this.form.card_bank,
                    id_front_image: this.form.card_front,
                    id_back_image: this.form.card_back,
                    author_name: this.form.author_name,
                };
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        author_apply(params).then((res) => {
                            if (res.code === 0) {
                                this.next(num);
                                this.person_info.audit_status = 1;
                                sessionStorage.setItem('person_info', this.person_info)
                            } else {
                                this.$message.warning(res.msg);
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            next(num) {
                if (this.step_num) {
                    this.step_num += 1;
                }
                this.active = num;
            },
            time_init() {
                this.set_time = setInterval(() => {
                    if (this.time > 0) {
                        this.time--;
                    } else {
                        this.$router.push({
                            path: "/index",
                        });
                    }
                }, 1000);
            },
            clear_time() {
                if (this.set_time) {
                    clearInterval(this.set_time);
                    this.set_time = null;
                }
            },
        },
        beforeDestroy() {
            this.clear_time();
        },
    };
</script>
<style lang="scss" scoped>
    .write_detail {
        background-color: #fafcff;

        .write_detail_top {
            width: 100%;
            height: 60px;
            background-color: #fff;
            box-shadow: 5px 0 5px #d2d2d2;

            .top_header {
                background-color: #fff;
            }
        }
    }

    @import "../../utils/tool.scss";
    // @include flex-center();
    .content {
        width: 950px;
        min-height: calc(100vh - 80px);
        margin: 0 auto;
    }

    .out_box {
        min-width: 500px;
        @include flex-column-center();
        padding: 50px 0;
    }

    .step_label {
        padding: 100px 0 10px;

        /deep/ .el-steps {
            justify-content: center;
        }
    }

    .content_white {
        background-color: #fff;
        margin-top: 20px;
        box-shadow: 0 0 5px #e4e4e4;
        min-height: 550px;
        border-radius: 4px;
    }

    .step_box {
        @include flex-column();

        .step_choose {
            @include flex-column();
            margin: 0 auto;
            line-height: 30px;

            span {
                margin-bottom: 30px;
                text-indent: 2em;
            }
        }

        .number_input {
            /deep/ {
                .el-input__inner::-webkit-outer-spin-button,
                .el-input__inner::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    appearance: none;
                    margin: 0;
                }
            }
        }
    }

    .step_next {
        @include flex-center();
        padding: 15px 0;

        /deep/ .el-button {
            padding: 8px 15px;
        }
    }

    .image_box {
        box-shadow: 2px 2px 6px #bbbbbb;
        width: 88px;
        height: 124px;
        border-radius: 2px;
        overflow: hidden;
    }

    .step_three {
        .success_word {
            @include flex-column-center();

            h3 {
                padding: 36px;
            }

            span {
                padding: 30px;
            }
        }
    }

    .card_box {
        @include flex-row();

        .card_item {
            @include flex-column-center();

            .card {
                width: 240px;
                height: 137px;
                margin: 0 20px;
                box-shadow: 0 0 5px #eeeeee;
            }
        }
    }

    /deep/ .el-form-item__label {
        width: 100px !important;
    }

    /deep/ .el-form-item__content {
        margin-left: 100px !important;
    }

    /deep/ .is-process {
        color: #666666 !important;
        border-color: #666666 !important;

        /deep/ .el-step__line-inner {
            color: #666666 !important;
        }

        /deep/ .el-step__icon {
            color: #666666 !important;
            border-color: #666666 !important;
        }
    }

    /deep/ .is-success {
        color: #409eff !important;
        border-color: #409eff !important;

        /deep/ .el-step__line-inner {
            color: #409eff !important;
        }

        /deep/ .el-step__icon {
            color: #409eff !important;
            border-color: #409eff !important;
        }
    }
</style>





